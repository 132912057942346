import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        style: {
          textTransform: "none",
          boxShadow: "none",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "#fff",
            background: "rgb(229, 156, 139)",
            "&:hover": {
              backgroundColor: `rgb(229, 156, 139)`,
              boxShadow: "none",
            },
          },
        },
      ],
    },
  },
});

export default theme;
