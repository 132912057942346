import { StyledButton } from "./styles";
import { ButtonProps } from "../types";
import { FC } from "react";

export const Button:FC<ButtonProps> = ({
  color,
  children,
  shadow = true,
  ...props
}) => (
  <StyledButton color={color} shadow={shadow} {...props}>
    {children}
  </StyledButton>
);
