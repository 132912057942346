const routes = [
  {
    path: "/",
    exact: true,
    component: "Home",
  },
  {
    path: "/consulta-virtual",
    exact: true,
    component: "ConsultaVirtual",
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: "/contacto",
    exact: true,
    component: "Contacto",
  },
  {
    path: "/sobre-mi",
    exact: true,
    component: "About",
  },
  {
    path: "/taller-gratuito",
    exact: true,
    component: "TallerGratuito",
  },
  {
    path: "/taller-nacer-juntas",
    exact: true,
    component: "TallerNacerJuntasLanding",
  },
  {
    path: "/ebook/:productId",
    exact: true,
    component: "Ebook",
  },
  {
    path: "/compra-success",
    exact: true,
    component: "SucessPayment",
  },
  {
    path: "/blog/amamantar-duele",
    exact: true,
    component: "Blog",
  },
];

export default routes;
